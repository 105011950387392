// custom typefaces
import "@fontsource/merriweather"
import "@fontsource/montserrat/variable.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"
import "./src/global.css"

// NProgress
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import "./nprogress.css"

export { wrapPageElement } from "./gatsby-shared"

export const onRouteUpdate = () => {
  NProgress.done()
}
